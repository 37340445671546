import { LOGIN, LOGOUT, UPDATE_FIAT_OPTION } from "./auth.types";
import { NotificationManager } from "react-notifications";
import CryptoJS, { HmacSHA256 } from "crypto-js";
import {
  authenticateHTTP,
  authenticateMerchantPOSUserHTTP,
  confirmLoginHTTP,
  confirmLoginSmsHTTP,
  registerMobileUserHTTP,
  getUserIdentityDetailsHTTP,
  getUserInfoHTTP,
  getUserProfileHTTP,
  getAccountInfoHTTP,
  getMerchantsByUserIdHTTP,
  createMerchantHTTP,
  checkVerificationLevel
} from "../../api/services";
import { getBalance } from "../Balance/balance.actions";
import { ALLOWED_VL } from "../../constants";

export const login = (payload, setLoading) => async dispatch => {
  try {
    const { data } = await authenticateHTTP(
      {},
      {
        headers: {
          Authorization: `Basic ${btoa(
            payload.username.trim() + ":" + payload.password
          )}`
        }
      }
    );

    if (data.Authenticated) {
      localStorage.setItem("merchantSessionToken", data.SessionToken);
      localStorage.setItem("user_id", data.UserId);

      const data2 = await checkRefreshToken();
      dispatch({
        type: LOGIN,
        payload: data2
      });
    }

    return data;
  } catch (error) {
    console.log(error.response);
    NotificationManager.error(
      "Datos ingresados incorrectos o usuario inactivo."
    );
    setLoading(false);
  }
};

export const checkRefreshToken = async () => {
  if (
    localStorage.getItem("merchantSessionToken") &&
    localStorage.getItem("user_id")
  ) {
    const { data } = await getUserInfoHTTP({
      UserId: parseInt(localStorage.getItem("user_id"))
    });

    const userProfile = await getUserProfile(data.UserName);
    // const userDetails = await getUserDetails(data.UserId);
    const accountInfo = await GetAccountInfo(data.AccountId);

    return {
      ...accountInfo,
      ...data,
      ...userProfile
      // ...userDetails,
    };
  } else {
    return false;
  }
};

export const getUserProfile = async UserName => {
  const { data } = await getUserProfileHTTP({
    Name: UserName
  });

  return data;
};

export const getUserDetails = async UserId => {
  const { data } = await getUserIdentityDetailsHTTP({ UserId });

  return data;
};

export const GetAccountInfo = async AccountId => {
  const { data } = await getAccountInfoHTTP({ AccountId, OMSId: 1 });

  return data;
};

export const AuthenticateMerchantPOSUser = (
  username,
  pin
) => async dispatch => {
  try {
    const { data } = await authenticateMerchantPOSUserHTTP({
      Username: username.trim(),
      LoginCode: pin.trim()
    });

    if (data.SessionToken) {
      localStorage.setItem("merchantSessionToken", data.SessionToken);
      localStorage.setItem("UserIdentifier", username);
      dispatch({
        type: LOGIN,
        payload: data
      });
    } else {
      if (data.result === false) {
        NotificationManager.error(
          "Datos ingresados incorrectos o usuario inactivo."
        );
      }
    }
    return data;
  } catch (e) {
    console.log(e);
  }
};

export const authenticateApiKey = (
  setLoading,
  UserId,
  APIKey,
  APISecret
) => async dispatch => {
  try {
    let nonce = localStorage.getItem("apiNonce");
    if (nonce) {
      nonce = parseInt(nonce);
    } else {
      nonce = 0;
    }

    nonce += 1;
    localStorage.setItem("apiNonce", nonce);

    const message = `${nonce}${UserId}${APIKey}`;

    const hash = HmacSHA256(message, APISecret);

    const hashInHex = hash.toString(CryptoJS.enc.Hex);

    const { data } = await authenticateHTTP(
      {},
      {
        headers: {
          APIKey: APIKey,
          Signature: hashInHex,
          UserId: UserId,
          Nonce: nonce
        }
      }
    );

    if (data.SessionToken) {
      localStorage.setItem("merchantSessionToken", data.SessionToken);
      localStorage.setItem("verification_level", data.VerificationLevel);
      localStorage.setItem("m_acct_id", data?.User?.AccountId);

      const userProfile = await getUserProfile(data.User.UserName);
      // const userDetails = await getUserDetails(data.User.UserId);
      const accountInfo = await GetAccountInfo(data.User.AccountId);

      const UserIdentifier = localStorage.getItem("UserIdentifier");

      dispatch({
        type: LOGIN,
        payload: {
          ...data,
          ...accountInfo,
          ...userProfile,
          UserIdentifier
        }
      });

      dispatch(getBalance(userProfile.AccountId));

      const { data: merchants } = await getMerchantsByUserIdHTTP(
        data.User.UserId
      );
      if (!merchants.length) {
        const { data: res } = await createMerchantHTTP({
          Description: "",
          MerchantBusinessLogoUrl: "",
          MerchantId: 0,
          KYCLevel: 0,
          MerchantAccountId: 0,
          MerchantName: userProfile.Name,
          MerchantTaxIdentifier: UserIdentifier,
          OwnerUserId: data.User.UserId,
          IsActive: true
        });
        console.log("Automatically created Merchant:");
        console.log(res);
      }

      return data;
    } else {
      NotificationManager.error(
        "Datos ingresados incorrectos o usuario inactivo."
      );
      setLoading(false);
    }
  } catch (error) {
    console.log(error);
    NotificationManager.error(
      "Datos ingresados incorrectos o usuario inactivo."
    );
    setLoading(false);
  }
};

export const updateFiatConversion = payload => async dispatch => {
  dispatch({
    type: UPDATE_FIAT_OPTION,
    payload: payload
  });
};

export const requestOTP = async phone => {
  try {
    const { data } = await registerMobileUserHTTP({
      MobileNumber: phone.replace("+", ""),
      NewRegistration: false,
      OperatorId: 1
    });

    if (data.UserProfileType !== "MerchantId") {
      return false;
    }

    if (data.UserId) {
      localStorage.setItem("user_id", data.UserId);
      return true;
    }

    return false;
  } catch (error) {
    console.log(error);
    const { status_code } = error.response.data.error;

    if (status_code === 10001) {
      NotificationManager.error("Número no registrado.");
    } else {
      NotificationManager.error("No se pudo enviar el código.");
    }

    return false;
  }
};

export const verifyOTP = async (phone, code) => {
  try {
    const { data } = await confirmLoginSmsHTTP({
      MobileNumber: phone.replace("+", ""),
      Code: code,
      UserId: parseInt(localStorage.getItem("user_id")),
      PinRecovery: false
    });

    if (data.errorcode !== 0) {
      return data.result;
    }

    const vlResult = await checkVerificationLevel({
      UserId: parseInt(localStorage.getItem("user_id"))
    });

    if (!ALLOWED_VL.includes(vlResult.data.VerificationLevel)) {
      return {
        error: {
          status: "CUSTOM_ERROR",
          data: vlResult.data,
          error: "NOT_ALLOWED_VL"
        }
      };
    }

    return data.result;
  } catch (error) {
    console.log(error.response);
    NotificationManager.error("Código inválido.");

    return false;
  }
};

export const confirmLogin = (
  MobileNumber,
  Code,
  UserIdentifier,
  Passcode = undefined
) => async dispatch => {
  try {
    localStorage.setItem("UserIdentifier", UserIdentifier);
    const cleanUserIdentifier = UserIdentifier.replaceAll("-", "");
    const cleanMobileNumber = MobileNumber.replaceAll("+", "");
    const { data } = await confirmLoginHTTP({
      MobileNumber: cleanMobileNumber,
      UserProfileType: "MerchantId",
      UserId: parseInt(localStorage.getItem("user_id")),
      Code: Passcode,
      Passcode: Code,
      UserIdentifier: cleanUserIdentifier,
      UserIdentifier2: ""
    });

    return data;
  } catch (e) {
    console.log(e);
  }
};

export const logout = () => async dispatch => {
  try {
    localStorage.removeItem("merchantSessionToken");
    localStorage.removeItem("access_token");
    localStorage.removeItem("m_acct_id");
    localStorage.removeItem("formattedNIT");
    localStorage.removeItem("user_id");
    localStorage.removeItem("UserIdentifier");
    localStorage.removeItem("verification_level");
    dispatch({
      type: LOGOUT
    });
  } catch (error) {
    console.log(error.response);
  }
};
