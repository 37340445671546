import { http } from "../http";

export const getAccountInfoHTTP = async data => {
  return http.post("GetAccountInfo", data);
};

export const getAccountPositionsHTTP = async data => {
  return http.post("GetAccountPositions", data);
};

export const getAccountTransactionDetailHistoryHTTP = async data => {
  return http.post("GetAccountTransactionDetailHistory", data);
};

export const getAccountWithdrawInfosHTTP = async data => {
  return http.post("GetAccountWithdrawInfos", data);
};

export const createAccountWithdrawInfoHTTP = async data => {
  return http.post("CreateAccountWithdrawInfo", data);
};

export const deleteAccountWithdrawInfoHTTP = async data => {
  return http.post("DeleteAccountWithdrawInfo", data);
};
