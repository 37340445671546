import React, { Suspense } from "react";
import ReactDOM from "react-dom";

import "./index.css";
import "onsenui/css/onsenui.css";
import "onsenui/css/onsen-css-components.css";

import App from "./App";
import i18next from "i18next";
import { Filters } from "./context/Filters";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import reportWebVitals from "./reportWebVitals";
import "./styles";
import { Provider } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";

import store from "./redux/store";
import "react-notifications/lib/notifications.css";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ["es"],
    fallbackLng: "es",
    debug: false,
    // Options for language detector
    detection: {
      order: ["path", "cookie", "htmlTag"],
      caches: ["cookie"]
    },
    // react: { useSuspense: false },
    backend: {
      loadPath: "/assets/locales/{{lng}}/translation.json"
    }
  });

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.NODE_ENV,
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
});

ReactDOM.render(
  <Filters>
    <Suspense fallback={null}>
      <Provider store={store}>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </Provider>
    </Suspense>
  </Filters>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
