import { ReactLazyPreload } from "../utils/lazyPreload";

const Dashboard = ReactLazyPreload(() => import("../views/Dashboard"));
const Terminals = ReactLazyPreload(() => import("../views/Terminals"));
const Settings = ReactLazyPreload(() => import("../views/Settings"));
const ProcessPayments = ReactLazyPreload(() =>
  import("../views/ProcessPayments")
);
const SendMoney = ReactLazyPreload(() => import("../views/SendMoney"));
const Movements = ReactLazyPreload(() => import("../views/Movements"));
const ExchangeBalance = ReactLazyPreload(() =>
  import("../views/ExchangeBalance")
);
const GetPayment = ReactLazyPreload(() => import("../views/GetPayment"));
const Transactions = ReactLazyPreload(() => import("../views/Transactions"));
const MovementDetails = ReactLazyPreload(() =>
  import("../views/MovementDetails")
);
const PaymentConfirmation = ReactLazyPreload(() =>
  import("../views/PaymentConfirmation")
);
const Withdrawals = ReactLazyPreload(() => import("../views/Withdrawals"));
const Withdraw = ReactLazyPreload(() => import("../views/Withdraw"));
const Index = ReactLazyPreload(() => import("../views/Npe/Index"));

let dashboardRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/terminals", component: Terminals },
  { path: "/settings", component: Settings },
  { path: "/payments", component: ProcessPayments },
  { path: "/payment-confirmation", component: PaymentConfirmation },
  { path: "/send/friends", component: SendMoney },
  { path: "/movements", component: Movements },
  { path: "/exchange", component: ExchangeBalance },
  { path: "/get-payment", component: GetPayment },
  { path: "/transactions", component: Transactions },
  { path: "/details", component: MovementDetails },
  { path: "/withdrawals", component: Withdrawals },
  { path: "/withdraw", component: Withdraw },
  { path: "/npe", component: Index }
];

export default dashboardRoutes;
