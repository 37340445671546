import { BALANCE_SUCCESS } from "./balance.types";

const initialState = {
  USD: {
    Amount: 0,
    Hold: 0
  },
  BTC: {
    Amount: 0,
    Hold: 0,
    NotionalRate: 1,
    NotionalValue: 0
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case BALANCE_SUCCESS:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

export default reducer;
