import { http } from "../http";

export const getUserInfoHTTP = async data => {
  return http.post("GetUserInfo", data);
};

export const getUserProfileHTTP = async data => {
  return http.post("GetUserProfile", data);
};

export const getUserIdentityDetailsHTTP = async data => {
  return http.post("GetUserIdentityDetails", data);
};

export const setUserProfileHTTP = async data => {
  return http.post("SetUserProfile", data);
};
