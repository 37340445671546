import "./App.css";
import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import "./styles/styles.scss";
import { NotificationContainer } from "react-notifications";
import history from "./history";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import authRoutes from "./routes/auth";
import dashboardRoutes from "./routes/dashboard";
import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import { useSelector } from "react-redux";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import { LOGIN } from "./redux/Auth/auth.types";
import { useDispatch } from "react-redux";
import { checkRefreshToken } from "./redux/Auth/auth.actions";
import { getBalance } from "./redux/Balance/balance.actions";
import LoadingScreen from "./views/LoadingScreen/index";

registerLocale("es", es);
setDefaultLocale("es", es);
const languages = [
  {
    code: "fr",
    name: "Français",
    country_code: "fr"
  },
  {
    code: "en",
    name: "English",
    country_code: "gb"
  },
  {
    code: "es",
    name: "Spanish",
    country_code: "sp"
  }
];

function App() {
  const { logged, user } = useSelector(state => state.auth);
  const [loading, setLoading] = useState(true);
  const [showSidebar, setShowSidebar] = useState(false);
  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find(l => l.code === currentLanguageCode);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(
    () => {
      document.body.dir = currentLanguage.dir || "ltr";
    },
    [currentLanguage, t]
  );

  useEffect(() => {
    async function checktoken() {
      const data = await checkRefreshToken();
      if (data && data.UserId) {
        dispatch(getBalance(data.AccountId));
        dispatch({
          type: LOGIN,
          payload: {
            ...data,
            logged: true
          }
        });
      }
      setLoading(false);
    }
    checktoken();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  useEffect(
    () => {
      const handlePopState = () => {
        if (!logged) {
          setLoading(true);
          history.push("/login");
          setLoading(false);
        }
      };

      window.addEventListener("popstate", handlePopState);

      return () => {
        window.removeEventListener("popstate", handlePopState);
      };
    },
    [logged, history]
  );

  return loading ? (
    <LoadingScreen />
  ) : (
    <div className="App main-bg">
      <Router history={history}>
        <Switch>
          {!logged ? (
            <>
              {authRoutes.map((route, key) => {
                return (
                  <Route
                    path={route.path}
                    component={route.component}
                    key={key}
                  />
                );
              })}
              <Route exact path="/*">
                <Redirect to="/login" />
              </Route>
            </>
          ) : (
            <>
              <div className="wrapper">
                <Sidebar
                  showSidebar={showSidebar}
                  setShowSidebar={setShowSidebar}
                />
                <div className="container">
                  <Header setShowSidebar={setShowSidebar} />
                  {dashboardRoutes.map((route, key) => {
                    return (
                      <Route
                        exact
                        path={route.path}
                        component={route.component}
                        key={key}
                      />
                    );
                  })}
                  <Route exact path="/*">
                    <Redirect
                      to={user?.MerchantPOSUserId ? "/payments" : "/dashboard"}
                    />
                  </Route>
                </div>
              </div>
            </>
          )}
        </Switch>
      </Router>
      <NotificationContainer />
    </div>
  );
}

export default App;
