import { useContext, useState, createContext } from "react";

const filtersContext = createContext("");

export const Filters = ({children}) => {
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [selectedStatus, setSelectedStatus] = useState(0);
    const [selectedTerminal, setSelectedTerminal] = useState(0);
    const [npeStep, setNpeStep] = useState(1);
    const [npeInfo, setNpeInfo] = useState({});
    const [amount, setAmount] = useState("0");
    return (
        <filtersContext.Provider
            value={{
                startDate,
                setStartDate,
                endDate,
                setEndDate,
                selectedStatus,
                setSelectedStatus,
                selectedTerminal,
                setSelectedTerminal,
                npeStep,
                setNpeStep,
                npeInfo,
                setNpeInfo,
                amount,
                setAmount
            }}
        >
            {children}
        </filtersContext.Provider>
    );
};

export const UseFilters = () => useContext(filtersContext);