import { BALANCE_SUCCESS } from "./balance.types";
import { getAccountPositionsHTTP } from "../../api/services";
import { NotificationManager } from "react-notifications";

export const getBalance = accountId => {
  return async dispatch => {
    try {
      const { data } = await getAccountPositionsHTTP({
        OMSId: 1,
        AccountId: accountId
      });

      const transformedData = data.reduce((acc, el) => {
        acc[el.ProductSymbol] = el;
        return acc;
      }, {});

      dispatch({
        type: BALANCE_SUCCESS,
        payload: transformedData
      });
    } catch (e) {
      console.log(e);
      NotificationManager.error("Ocurrió un error al cargar el balance");
    }
  };
};
