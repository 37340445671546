import { useSelector } from "react-redux";
import { GiHamburgerMenu } from "react-icons/gi";
import userImage from "../assets/images/userImage.png";
import { useTranslation } from "react-i18next";

const Header = ({ setShowSidebar }) => {
  const { user } = useSelector(state => state.auth);
  const { t } = useTranslation();

  const Name = useSelector(
    state =>
      state.auth?.user?.UserProfileType === "MerchantId"
        ? state.auth?.user?.AccountName
        : state.auth?.user?.MerchantName
  );

  return (
    <div className="header">
      <GiHamburgerMenu
        className="header-menu"
        onClick={() => setShowSidebar(true)}
      />
      <div className="avatar">
        <div>
          <img
            alt=""
            src={user?.AvatarImage ? user?.AvatarImage : userImage}
            onError={e => (e.target.src = userImage)}
          />
          {/* <img
            className="pencilImg"
            alt=""
            src={userPencil}
          >
          </img> */}
        </div>
      </div>
      <div className="header-info">
        <div className="welcome">{t("Bienvenido/a")}</div>
        <div className="name">
          {Name}
          {/* Punto de Venta */}
        </div>
      </div>
    </div>
  );
};

export default Header;
