import axios from "axios";
import history from "../history";
import store from "../redux/store";
import { logout } from "../redux/Auth/auth.actions";
import { NotificationManager } from "react-notifications";

const BASE_URL = window.config.serverUri;

const headers = {
  "Content-Type": "application/json"
};

const injectToken = config => {
  try {
    const token = localStorage.getItem("merchantSessionToken");

    if (token != null) {
      config.headers.aptoken = token;
    }
    return config;
  } catch (error) {
    throw new Error(error);
  }
};

class Http {
  #instance = null;

  constructor(baseURL) {
    this.baseURL = baseURL;
  }

  get http() {
    return this.#instance != null ? this.#instance : this.initHttp();
  }

  initHttp() {
    const axiosInstance = axios.create({
      baseURL: this.baseURL,
      headers
    });

    axiosInstance.interceptors.request.use(injectToken, error =>
      Promise.reject(error)
    );
    axiosInstance.interceptors.response.use(
      res => {
        return res;
      },
      async err => {
        if (err.response) {
          if (err.response.status === 404) {
            store.dispatch(logout());
            NotificationManager.error(
              "Información de inicio de sesión no válida"
            );
            history.push("/login");
            return Promise.reject(err);
          }
        }

        return Promise.reject(err);
      }
    );

    this.#instance = axiosInstance;
    return axiosInstance;
  }

  request(config) {
    return this.http.request(config);
  }

  get(url, config) {
    return this.http.get(url, config);
  }

  post(url, data, config) {
    return this.http.post(url, data, config);
  }

  put(url, data, config) {
    return this.http.put(url, data, config);
  }

  delete(url, config) {
    return this.http.delete(url, config);
  }
}

export const http = new Http(BASE_URL);
