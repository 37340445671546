import { matchPath } from "react-router-dom";
import authRoutes from "./auth";
import dashboardRoutes from "./dashboard";

const routes = [...authRoutes, ...dashboardRoutes];

export const preloadRouteComponent = to => {
  const component = findComponentForRoute(to, routes);

  if (component && component.preload) {
    component.preload();
  }
};

const findComponentForRoute = (path, routes) => {
  const matchingRoute = routes.find(route =>
    matchPath(path, {
      path: route.path,
      exact: route.exact
    })
  );

  if (matchingRoute && matchingRoute.routes) {
    return findComponentForRoute(path, matchingRoute.routes);
  }

  return matchingRoute ? matchingRoute.component : null;
};
