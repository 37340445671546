import { http } from "../http";

export const createMerchantHTTP = async data => {
  return http.post("CreateMerchant", data);
};

export const getMerchantsByUserIdHTTP = async userId => {
  return http.get(`GetMerchants?UserId=${userId}`);
};

export const getMerchantByUserIdHTTP = async userId => {
  return http.get(`GetMerchant?UserId=${userId}`);
};

export const createMerchantPOSUserHTTP = async data => {
  return http.post("CreateMerchantPOSUser", data);
};

export const updateMerchantPOSUserHTTP = async data => {
  return http.post("UpdateMerchantPOSUser", data);
};

export const setMerchantPOSUserPinHTTP = async data => {
  return http.post("SetMerchantPOSUserPin", data);
};

export const GetMerchantPOSUsersHTTP = async id => {
  return http.get(`GetMerchantPOSUsers?MerchantId=${id}`);
};
