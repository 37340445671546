import "../../styles/views/LoadingScreen/index.css";
import Loader from "../../components/Loader";
import PanelLogo from "../../assets/images/Chivo-Wallet-Panel-de-Comercio-Logo.svg";

function LoadingScreen() {
  return (
    <div className="loading-screen">
      <img className="loading-screen-image" alt="" src={PanelLogo} />
      <Loader className="loading-screen-spinner" />
    </div>
  );
}

export default LoadingScreen;
