export const CONVERT_OPTIONS = {
  none: "none", //"do",
  usd: "USD", // "cu",
  btc: "BTC"
};

export const InvoiceStatuses = {
  Pending: "Pending",
  Paid: "Paid",
  Expired: "Expired",
  Canceled: "Canceled",
  PartialPayment: "PartialPayment"
};

export const invoiceStatusMapping = {
  Pending: "En proceso",
  Paid: "Aprobado",
  Expired: "Rechazado",
  Canceled: "Cancelado",
  PartialPayment: "Pago no se ajusta a la factura"
};

export const ALLOWED_VL = [9, 11, 12, 13];

export const INVOICE_EXP_MIN = 20;

export const USD_DECIMAL_PLACES = 2;
export const BTC_DECIMAL_PLACES = 8;
