import {
  GET_CURRENCIES,
  CREATE_PAYMENT,
  CANCEL_PAYMENT,
  RESET_PAYMENT,
  CONFIRM_PAYMENT,
  TRACK_PAYMENT,
  RESET_CURRENCIES
} from "./processPayment.types";

const INITIAL_STATE = {
  availableCurrencies: [],
  step: 1
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CURRENCIES:
      return {
        ...state,
        availableCurrencies: action.payload
      };

    case CREATE_PAYMENT:
      return {
        ...state,
        payment: action.payload,
        step: 2
      };

    case CANCEL_PAYMENT:
      return {
        ...state,
        payment: {},
        step: 1
      };

    case RESET_PAYMENT:
      return {
        ...state,
        payment: null,
        step: 1
      };

    case TRACK_PAYMENT:
      return {
        ...state,
        payment: action.payload,
        step: 2
      };

    case CONFIRM_PAYMENT:
      return {
        ...state,
        payment: null,
        step: 1
      };

    case "GET_DEPOSIT_INFO":
      return {
        ...state,
        depositInfo: action.payload
      };

    case RESET_CURRENCIES:
      return {
        ...state,
        availableCurrencies: null,
        step: 1
      };

    default:
      return state;
  }
};

export default reducer;
