import { ReactLazyPreload } from "../utils/lazyPreload";

export const ROUTE_PATHS = {
  otp: "/otp",
  nit: "/nit",
  pos: "/pos",
  login: "/login",
  register: "/register"
};

const SignIn = ReactLazyPreload(() => import("../views/Auth/SignIn"));
const SignUp = ReactLazyPreload(() => import("../views/Auth/SignUp"));
const NIT = ReactLazyPreload(() =>
  import("../views/Auth/SignIn/components/NIT/NIT")
);
const OTP = ReactLazyPreload(() =>
  import("../views/Auth/SignIn/components/OTP/OTP")
);
const POS = ReactLazyPreload(() =>
  import("../views/Auth/SignIn/components/POS/POS")
);

let authRoutes = [
  { path: ROUTE_PATHS.login, component: SignIn },
  { path: ROUTE_PATHS.otp, component: OTP },
  { path: ROUTE_PATHS.nit, component: NIT },
  { path: ROUTE_PATHS.pos, component: POS },
  { path: ROUTE_PATHS.register, component: SignUp }
];

export default authRoutes;
