import { http } from "../http";

export const getInvoiceHTTP = async (data, controller) => {
  return http.post("GetInvoice", data, controller);
};

export const getInvoicesHTTP = async data => {
  return http.post("GetInvoices", data);
};

export const createInvoiceHTTP = async data => {
  return http.post("CreateInvoice", data);
};

export const cancelInvoiceHTTP = async data => {
  return http.post("CancelInvoice", data);
};

export const getInvoiceByIdHTTP = async invoiceId => {
  return http.get(`GetInvoice?InvoiceId=${invoiceId}`);
};
