import BilleteraIcon from "../assets/images/billetera_icon.png";
import ProcesarPagos from "../assets/images/procesarpagos_icon.png";
import TerminalesIcon from "../assets/images/terminales_icon.png";
import ConfiguracionIcon from "../assets/images/configuracion_icon.png";
import TransaccionIcon from "../assets/images/transaccion_icon.png";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/Auth/auth.actions";
import { preloadRouteComponent } from "../routes";
import { useLocation } from "react-router-dom";
import packageInfo from "../../package.json";

const Sidebar = ({ history, showSidebar, setShowSidebar }) => {
  const { user } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();

  const userIsMerchant = useSelector(
    state => state.auth?.user?.UserProfileType === "MerchantId"
  );

  let commerceOptions = [
    {
      label: t("Procesar pagos"),
      path: "/payments",
      icon: ProcesarPagos
    },
    {
      label: t("POS Transacciones"),
      path: "/transactions",
      icon: TransaccionIcon
    }
  ];
  if (user.UserProfileType === "MerchantId") {
    commerceOptions = [
      {
        label: t("Billetera"),
        path: "/dashboard",
        icon: BilleteraIcon
      },
      ...commerceOptions
    ];
    commerceOptions = commerceOptions.concat([
      {
        label: t("Puntos de Venta"),
        path: "/terminals",
        icon: TerminalesIcon
      },
      {
        label: t("Configuración"),
        path: "/settings",
        icon: ConfiguracionIcon
      }
    ]);
  }

  let userIdentifier = localStorage.getItem("UserIdentifier");
  if (userIsMerchant) {
    userIdentifier = `${userIdentifier.slice(0, 4)}-${userIdentifier.slice(
      4,
      10
    )}-${userIdentifier.slice(10, 13)}-${userIdentifier.slice(13)}`;
  } else {
    if (user.MerchantIdentifier) {
      userIdentifier = user.MerchantIdentifier;
    }
    userIdentifier = `${userIdentifier.slice(0, 4)}-${userIdentifier.slice(
      4,
      10
    )}-${userIdentifier.slice(10, 13)}-${userIdentifier.slice(13)}`;
  }

  return (
    <div className={`sidebar ${showSidebar ? "visible" : ""}`}>
      <div className={`sidebar-wrap ${showSidebar ? "visible" : ""}`}>
        {showSidebar && (
          <div className="sidebar-close" onClick={() => setShowSidebar(false)}>
            &times;
          </div>
        )}
        <div className="sidebar-items">
          <div className="sidebar-logo" />
          <div className="sidebar-button">
            <div id="sidebar-userDni">
              {userIdentifier ||
                user?.MerchantIdentifier ||
                user?.UserIdentifier}
            </div>
          </div>
          {commerceOptions.map((option, key) => {
            return (
              <div
                className={`sidebar-option ${
                  location.pathname === option.path
                    ? "sidebar-option_active"
                    : ""
                }`}
                id={`sidebarOption${key}`}
                key={key}
                onClick={() => {
                  history.push(option.path);
                  setShowSidebar(false);
                }}
                onMouseEnter={() => preloadRouteComponent(option.path)}>
                <div className="sidebar-optImage">
                  <img src={option.icon} alt={option.label} />
                </div>
                <span>{option.label}</span>
              </div>
            );
          })}
          <div className="logout-version">
            <div className="logout" onClick={() => dispatch(logout())}>
              {t("Cerrar sesión")}
            </div>
            <div className="version">v {packageInfo.version}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default withRouter(Sidebar);
