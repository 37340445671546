import { http } from "../http";

export const authenticateMerchantPOSUserHTTP = async data => {
  return http.post("AuthenticateMerchantPOSUser", data);
};

export const confirmLoginHTTP = async data => {
  return http.post("ConfirmLogin", data);
};

export const authenticateHTTP = async (data, config) => {
  return http.post("Authenticate", data, config);
};

export const registerMobileUserHTTP = async data => {
  return http.post("RegisterMobileUser", data);
};

export const confirmLoginSmsHTTP = async data => {
  return http.post("ConfirmLoginSms", data);
};

export const checkVerificationLevel = async data => {
  return http.post("GetVerificationLevel", data);
};
